import React from 'react'
import Logo from '../images/img-00.png'

export const Header = () => {
    return (
        <header className="d-flex flex-column justify-content-center my-5">
            <img className="col-lg-3 col-md-5 col-sm-8 col-9 align-self-center" src={Logo} alt="Mojtaba Arabzade`" />
            <h6 className="align-self-center mt-4 text-dark text-uppercase">Iranian Artist</h6>
        </header>
    )
}
