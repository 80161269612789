import React, { 
    // useState 
} from 'react'
import { imgArr } from './Images'
// import Modal from 'react-bootstrap/Modal'
import Img06 from '../images/img-06.jpg'
import Img09 from '../images/img-09.jpg'

// function MyVerticallyCenteredModal(props) {
//     return (
//         <Modal
//             {...props}
//             size="lg"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//         >
//             <Modal.Header closeButton>
//             </Modal.Header>
//             <Modal.Body>
//                 {imgArr.map(img => <img src={img} alt='img' />)}
//         </Modal.Body>
//         </Modal>
//     )
// }

export const Img = () => {

    // const [modalShow, setModalShow] = useState(false);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <img src={Img06} className='w-100' alt='Mojtaba Arabzadeh Painting' />
                </div>
                <div className='col-lg-6'>
                    <img src={Img09} className='w-100' alt='Mojtaba Arabzadeh Painting' />
                </div>
            </div>
            <div className='row'>
                {
                    imgArr.map((img, i) => (
                        <div key={i} className='col-lg-3 my-2'>
                            <img
                                src={img}
                                className='w-100'
                                // onClick={() => setModalShow(true)}
                                alt='Mojtaba Arabzadeh Painting' />
                        </div>
                    ))
                }
            </div>
            {/* <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            /> */}
        </div>
    )
}
