import Img02 from '../images/img-02.jpg'
import Img03 from '../images/img-03.jpg'
import Img05 from '../images/img-05.jpg'
import Img06 from '../images/img-06.jpg'
import Img07 from '../images/img-07.jpg'
import Img09 from '../images/img-09.jpg'
import Img10 from '../images/img-10.jpg'
import Img11 from '../images/img-11.jpg'
import Img12 from '../images/img-12.jpg'
import Img13 from '../images/img-13.jpg'
import Img14 from '../images/img-14.jpg'
import Img15 from '../images/img-15.jpg'
import Img17 from '../images/img-17.gif'
import Img19 from '../images/img-19.jpg'
import Img20 from '../images/img-20.jpg'
import Img21 from '../images/img-21.jpg'
import Img22 from '../images/img-22.jpg'
import Img23 from '../images/img-23.jpg'
import Img24 from '../images/img-24.jpg'
import Img25 from '../images/img-25.jpg'
import Img26 from '../images/img-26.jpg'
import Img27 from '../images/img-27.jpg'
import Img28 from '../images/img-28.jpg'
import Img30 from '../images/img-30.jpg'
import Img31 from '../images/img-31.jpg'
import Img32 from '../images/img-32.jpg'
import Img33 from '../images/img-33.jpg'
import Img34 from '../images/img-34.jpg'
import Img35 from '../images/img-35.jpg'
import Img36 from '../images/img-36.jpg'
import Img37 from '../images/img-37.jpg'
import Img38 from '../images/img-38.jpg'
import Img39 from '../images/img-39.jpg'
import Img41 from '../images/img-41.jpg'
import Img42 from '../images/img-42.jpg'
import Img43 from '../images/img-43.jpg'
import Img44 from '../images/img-44.jpg'
import Img45 from '../images/img-45.jpg'
import Img46 from '../images/img-46.jpg'
import Img47 from '../images/img-47.jpg'
import Img48 from '../images/img-48.jpg'
import Img49 from '../images/img-49.jpg'
import Img50 from '../images/img-50.jpg'

export const imgArr = [
Img02,
Img03,
Img05,
Img06,
Img07,
Img09,
Img10,
Img11,
Img12,
Img13,
Img14,
Img15,
Img17,
Img19,
Img20,
Img21,
Img22,
Img23,
Img24,
Img25,
Img26,
Img27,
Img28,
Img30,
Img31,
Img32,
Img33,
Img34,
Img35,
Img36,
Img37,
Img38,
Img39,
Img41,
Img42,
Img43,
Img44,
Img45,
Img46,
Img47,
Img48,
Img49,
Img50,
]