import React from 'react'
import Avatar from '../images/img-000.png'

export const Footer = () => {
    return (
        <footer className="row d-flex flex-column justify-content-center bg-light p-5">
            <img className="avatar align-self-center mb-3 img-fluid rounded-circle col-lg-3" src={Avatar} alt="Painting by Mojtaba Arabzadeh - Iranian Painter" />
            <p className="col-lg-4 text-center align-self-center text-dark">
                Mojtaba Arabzadeh (مجتبی عربزاده) born in Ahvaz, Iran in 1983 and after entering the School of Visual Arts and passing the primary education of
                art, at age of 18, I entered the university and started to study painting and I began to paint professorially.
                </p>
            <a className="d-flex align-self-center justify-content-center mt-3 text-decoration-none" href="https://www.instagram.com/mojtaba.arabzade/">
                <i className="fab fa-instagram fa-2x " />
            </a>
        </footer>
    )
}
