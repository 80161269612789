import React from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Header } from './components/Header'
import { Img } from './components/Img'
import { Footer } from './components/Footer'

export default function App() {
  return (
    <div className="container-fluid">
        <Header />
        <Img />
        <Footer />
    </div>
  );
}
